/* global window */
window.jQuery = window.$ = require('jquery');

const $ = window.$;

require('datatables.net')(window, $);
require('datatables.net-fixedcolumns')( window, $ );

require('bootstrap');

$(() => {
});
